import { AtSign, KeyRound } from 'lucide-react';
import { convertArrayToOptionType, EMAIL_REGEX, FormField, USER_ROLES } from 'src/helpers';
import * as Yup from 'yup';

export type ILoginForm = {
  email: string,
  password: string

}

export type IRegistrationForm = {
  email: string,
  password: string
  confirm_password: string,
  is_accept: boolean,
  role: string,
  referralCode?: string
}

export type IForgotForm = {
  email: string,
}

export type IResetPassword = {
  email: string,
  password: string
  confirm_password: string,

}


export const loginFormValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/, "Password should be at least 1 capital letter , 1 special character, 1 small letter")
    .min(8, "Minimum 8 characters")
    .max(16, "Maximum 16 characters")
    .required('Password is required'),
});


export const registrationFormValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required')
    .matches(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/, "Password should be at least 1 capital letter , 1 special character, 1 small letter")
    .min(8, "Minimum 8 characters")
    .max(16, "Maximum 16 characters"),
  confirm_password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required')
    .test('passwords-match', 'Passwords not match', function (value) {
      return this.parent.password === value;
    }),
  is_accept: Yup.boolean().test('is_accept_term', 'please read and accept conditions ', function (value) {
    return this.parent.is_accept === true;
  }),
  role: Yup.string().required("Role is required")
});


export const ResetPasswordFormValidationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/, "Password should be at least 1 capital letter , 1 special character, 1 small letter")
    .min(8, "Minimum 8 characters")
    .max(16, "Maximum 16 characters")
    .required('Password is required'),
  confirm_password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .required('Confirm Password is required')
    .test('passwords-match', 'Passwords not match', function (value) {
      return this.parent.password === value;
    }),
});


export const forgotFormValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
});


export const login_initialValues: ILoginForm = {
  email: '',
  password: '',
};

export const forgot_initialValues: IForgotForm = {
  email: '',
};


export const registration_initialValues: IRegistrationForm = {
  email: '',
  password: '',
  confirm_password: "",
  is_accept: false,
  role: ""
};


export const resetpassword_initialValues: IResetPassword = {
  email: '',
  password: '',
  confirm_password: "",
};

export const LOGIN_FORM_FIELDS: FormField[] = [
  {
    label: 'Email',
    dataKey: 'email',
    componentType: 'input',
    componentProps: {
      type: 'email',
      inputPrefixIcon: <AtSign className='text-gray-500' size={16} />
    }
  },
  {
    label: 'Password',
    dataKey: 'password',
    componentType: 'input',
    componentProps: {
      type: 'password',
      inputPrefixIcon: <KeyRound className='text-gray-500' size={16} />
    }
  }
];


export const FORGOT_FORM_FIELDS: FormField[] = [
  {
    label: 'Email',
    dataKey: 'email',
    componentType: 'input',
    componentProps: {
      type: 'email',
      inputPrefixIcon: <AtSign className='text-gray-500' size={16} />
    }
  },
];


export const REGISTRATION_FORM_FIELDS: FormField[] = [
  {
    label: 'Email',
    dataKey: 'email',
    componentType: 'input',
    componentProps: {
      type: 'email',
      required:true,
      inputPrefixIcon: <AtSign className='text-gray-500' size={16} />,
    }
  },
  {
    label: 'Role',
    dataKey: 'role',
    componentType: 'options',
    componentProps: {
      required:true,
      options: convertArrayToOptionType(Object.values(USER_ROLES))
    }
  },
  {
    label: 'Password',
    dataKey: 'password',
    componentType: 'input',
    componentProps: {
      type: 'password',
      required:true,
      inputPrefixIcon: <KeyRound className='text-gray-500' size={16} />
    }
  },
  {
    label: 'Confirm Password',
    dataKey: 'confirm_password',
    componentType: 'input',
    componentProps: {
      type: 'password',
      required:true,
      inputPrefixIcon: <KeyRound className='text-gray-500' size={16} />
    }
  },
  {
    label: 'Referral Code',
    dataKey: 'referralCode',
    componentType: 'input',
    componentProps: {
      type: 'text',
    }
  },
];


export const RESET_PASSWORD_FORM_FIELDS: FormField[] = [
  {
    label: 'Password',
    dataKey: 'password',
    componentType: 'input',
    componentProps: {
      type: 'password',
      inputPrefixIcon: <KeyRound className='text-gray-500' size={16} />
    }
  },
  {
    label: 'Confirm Password',
    dataKey: 'confirm_password',
    componentType: 'input',
    componentProps: {
      type: 'password',
      inputPrefixIcon: <KeyRound className='text-gray-500' size={16} />
    }
  }
];
