import { FINANCE_SERVICE1, FINANCE_SERVICE2, FINANCE_SERVICE3, FINANCE_SERVICE4, FINANCE_SERVICE5, FINANCE_SERVICE6, LOAN_STEP1, LOAN_STEP2, LOAN_STEP3, LOAN_STEP4 } from "src/helpers";

export const services = [
    {
      icon: FINANCE_SERVICE1,
      title: "Instant EMI Options",
    },
    {
      icon: FINANCE_SERVICE2,
      title: "Minimum Documentation",
    },
    {
      icon: FINANCE_SERVICE3,
      title: "No Collateral",
    },
    {
      icon: FINANCE_SERVICE4,
      title: "Low Interest Rates",
    },
    {
      icon: FINANCE_SERVICE5,
      title: "Pan-India Presence",
    },
    {
      icon: FINANCE_SERVICE6,
      title: "Doorstep Services",
    },
  ];

  export const LOAN_STEPS = [
    {
      id: 1,
      title: "Application Submit",
      description: "In a free hour, when our power of choice is untrammeled and when nothing prevents.",
      image: LOAN_STEP1
    },
    {
      id: 2,
      title: "Review & Verification",
      description: "Back to the drawing board, show grit, for we should have a meeting to discuss the details.",
      image: LOAN_STEP2
    },
    {
      id: 3,
      title: "Loan Approval",
      description: "Hit the ground running, do I have consent to record this meeting, quick sync helicopter view.",
      image: LOAN_STEP3
    },
    {
      id: 4,
      title: "Loan Disbursement",
      description: "Radical candor, upsell this is a no-brainer, no need to talk to users, just base it on the data.",
      image: LOAN_STEP4
    },
  ];